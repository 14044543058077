body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.popup-content{
  width: 100%!important;
}
.popup-overlay{
  z-index: 9999999999999999999999999999999999999999999999999999999999999999999!important;
}
iframe{
  border: 0!important;
}
.popup-content a{
  font-size: 100px;
  color: green;
}
.selectedit{
  text-align: center;
  border: 3px solid green;
  padding: 10px;

}